<template lang="html">
  <div>
    <main v-html="data.Description" />
  </div>
</template>

<script>
import CMSService from "../CMS-Service.js";
export default {
  props: {
    url: String,
  },
  data() {
    return {
      data: null,
      loading: true,
    };
  },
  created() {
    CMSService.getCollectionEntries("Projekte").then((data) => {
      for (var i = 0; i < data.data.entries.length; i++) {
        if (data.data.entries[i].url == this.url) {
          this.data = data.data.entries[i];
        }
      }
      if (this.data == null) {
        this.$router.push({ name: "404" });
      }
    });
    this.loading = false;
  },
};
</script>

<style lang="css" scoped>
@import "../main.scss";

main {
  width: 80%;
  max-width: 1080px;
  padding: 150px 10%;
  padding-top: 16px;
  margin-left: auto;
  margin-right: auto;
}
main >>> .image_single {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  padding-bottom: 10px;
}

main >>> p,
h1,
h2,
h3,
h4,
h5,
ul,
ol,
img,
div,
video,
main >>> .image_grid_3,
main >>> .image_grid_2 {
  padding: 25px 0;
}

main >>> img {
  max-width: 100%;
}

main >>> video {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

main >>> .image_grid_3,
main >>> .image_grid_2 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

main >>> .image_grid_3 img {
  width: calc(33.33% - 20px);
  min-width: 225px;
  padding: 10px;
}
main >>> .image_grid_2 img {
  width: calc(50% - 20px);
  min-width: 337.5px;
  padding: 10px;
}
main >>> .big_img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>
